import React from "react";
import { useEffect, useState } from "react";
import styles from "../games.module.scss";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../utils/constants";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  getTrueOrFalseGame,
  submitTrueOrFalseGame,
} from "../../../../store/reducers/trueOrFalseGame";
import Confetti from "react-confetti"; // Import Confetti component
import { useWindowSize } from "react-use"; // To get the current window size
import { me } from "../../../../store/reducers/user";

const TrueOrFalse = ({isPlayedIntroGame}) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth?.data?.user);
  const [getQuestions, setGetQuestions] = useState([]);
  const { width, height } = useWindowSize(); // Get current window dimensions for confetti

  // State to track the current question index, game finished status, and answer correctness
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [gameFinished, setGameFinished] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(null);

  const [correctCount, setCorrectCount] = useState(0);

  // State variables to hold current question data
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentText, setCurrentText] = useState(null);

  useEffect(() => {
    // Fetch trivia questions when the component loads
    dispatch(getTrueOrFalseGame()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constants.THUNK_STATUS.FULFILLED:
          setGetQuestions(response?.payload?.questions);
          break;
        default:
          break;
      }
    });
  }, [dispatch, isPlayedIntroGame]);

  useEffect(() => {
    if (getQuestions?.length > 0 && !gameFinished) {
      const timeout = setTimeout(() => {
        setCurrentQuestionIndex((prevIndex) => {
          if (prevIndex < getQuestions.length - 1) {
            setIsCorrectAnswer(null);
            return prevIndex + 1;
          } else {
            setGameFinished(true);
            return prevIndex; // Keep the index the same when game is finished
          }
        });
      }, 10000); // Change question every 10 seconds

      return () => clearTimeout(timeout); // Cleanup timeout on unmount or re-run
    }
  }, [getQuestions, currentQuestionIndex, gameFinished]);

  // Update the current question, image, text, and options when currentQuestionIndex changes
  useEffect(() => {
    if (getQuestions && currentQuestionIndex >= 0) {
      const question = getQuestions[currentQuestionIndex];
      setCurrentQuestion(question);
      setCurrentImage(question?.image);
      setCurrentText(question?.text);
    }
  }, [currentQuestionIndex, getQuestions]);

  if (!getQuestions || getQuestions.length === 0) {
    return (
      <div className={styles.gamesWrapper}>
        <div>Loading questions...</div>
      </div>
    );
  }
  const resetGame = () => {
    setCurrentQuestionIndex(0);
    setGameFinished(false);
    setCorrectCount(0);
    setIsCorrectAnswer(null);
  };
  // Display "Game Finished" when all questions are shown
  if (gameFinished) {
    return (
      <div className={styles.gamesWrapper}>
        <div className={styles.gameFinished}>
          <img src="../../images/right_party.gif" alt="party" />
          {correctCount >= 5 && (
            <>
              {/* Render Confetti when the player scores 5 or more */}
              <Confetti width={width} height={height} />
              <h1>Congratulations!</h1>
            </>
          )}
          {correctCount < 5 && <h1>Nice Try!</h1>}

          <p>
            You scored <b>{correctCount}/10</b>!{" "}
            {correctCount < 5 && "Almost a perfect score!"}
            {correctCount >= 5 && <b>You Rock!</b>}
          </p>
          {userDetails?.isNewUser && (
            <p>You earned a free consultation from the Credit Genius</p>
          )}
          <p>
            Now try your hand at another game or get a credit consultation with
            the Credit Genius
          </p>
          <button type="button" className="dfltBtn" onClick={resetGame}>
            <span>PLAY AGAIN</span>
          </button>
        </div>
      </div>
    );
  }

  const handleSubmit = (answer, questionId) => {
    if (isCorrectAnswer !== null) return; // Prevent re-submission

    // Submit the answer
    const payload = {
      answer,
      questionId: questionId,
    };
    dispatch(
      submitTrueOrFalseGame({ payload, isTestEnv: constants.IS_TEST_ARRAY_URL })
    ).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constants.THUNK_STATUS.FULFILLED:
          if (response?.payload?.isCorrect) setCorrectCount(correctCount + 1);
          setIsCorrectAnswer(response?.payload?.isCorrect);
          setTimeout(() => {
            setIsCorrectAnswer(null);
            setCurrentQuestionIndex((prevIndex) => {
              if (prevIndex < getQuestions.length - 1) {
                return prevIndex + 1;
              } else {
                setGameFinished(true);
                return prevIndex; // Stay on the last question when finished
              }
            });
          }, 1000); // Delay of 2 seconds before moving to the next question
          return;
        default:
          return;
      }
    });
  };

  return (
    <div className={styles.gamesWrapper}>
      <div className={styles.gamesBoxHeader}>
        <h4>True Or False Game</h4>
        <div className={styles.gamesBoxHeaderRight}>
          Question:{" "}
          <b>
            <span>
              {currentQuestionIndex + 1} / {isPlayedIntroGame ? 10 : 3}
            </span>
          </b>
        </div>
      </div>
      <div className={styles.gameWrapperBox}>
        <div className={styles.gameWrapperImg}>
          {/* Render the current image */}
          <img src={currentImage} alt="game_img" text="game_img" />
        </div>
        <div className={styles.gamesContent}>
          {/* Display the current question */}
          <h4>{currentText}</h4>
          {/* Timer resets with currentQuestionIndex */}
          <div className={styles.timer}>
            <CountdownCircleTimer
              key={`timer-${currentQuestionIndex}`} // Ensure unique key for each question
              isPlaying={!gameFinished} // Ensure it stops when the game finishes
              duration={10}
              size={80}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[7, 5, 2, 0]}
              strokeWidth={5}
              onComplete={() => {
                if (!gameFinished) {
                  return { shouldRepeat: false }; // Prevent repeating indefinitely
                }
              }}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </div>
          {isCorrectAnswer === true && (
            <div className={styles.rightAnswerGif}>
              <img src="../../images/party.gif" alt="party" />
            </div>
          )}
          {isCorrectAnswer === true && (
            <div className={styles.answerBox}>
              <div className={styles.right}>
                <img src="../../images/check_icon.svg" alt="success" />
                <h5>Outstanding!!</h5>
              </div>
            </div>
          )}
          {isCorrectAnswer === false && (
            <div className={styles.answerBox}>
              <div className={styles.wrong}>
                <img src="../../images/wrong-icon.svg" alt="wrong" />
                <h5>Wrong Answer</h5>
              </div>
            </div>
          )}
          <div className={styles.trueFalseAction}>
            <button
              type="button"
              onClick={() => handleSubmit(true, currentQuestion?.id)}
            >
              True
            </button>
            <button
              type="button"
              onClick={() => handleSubmit(false, currentQuestion?.id)}
              className={styles.false}
            >
              False
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrueOrFalse;
