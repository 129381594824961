// import React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./faq.module.scss";

const testimonialScrl = {
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1261: {
      items: 3,
    },
  },
};

const Faq = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPressMenuOpen, setIsPressMenuOpen] = useState(false);
  const [isBuzzMenuOpen, setIsBuzzMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const header = document.querySelector(".siteTopNavbar");
    const scrollTop = window.scrollY;
    if (header) {
      const navPosition = header.offsetTop;
      const navTop = window.scrollY || document.documentElement.scrollTop;
      if (navPosition < navTop) {
        header?.classList?.add("stickyHeader");
      } else {
        header?.classList?.remove("stickyHeader");
      }
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - (window.innerWidth < 992 ? 150 : 120),
        behavior: "smooth",
      });
    }
  };

  const togglePressMenu = () => {
    setIsBuzzMenuOpen(false);
    setIsPressMenuOpen((prevState) => !prevState);
  };
  const toggleBuzzMenu = () => {
    setIsPressMenuOpen(false);
    setIsBuzzMenuOpen((prevState) => !prevState);
  };

  const handleMenuClick = (sectionId) => {
    toggleMenu();
    scrollToSection(sectionId);
  };

  const handleMenuLinkClick = (sectionId) => {
    setIsMenuOpen(false);
    scrollToSection(sectionId);
  };

  return (
    <>
      {/* <Header/> */}

      <div
        className={`${styles.topMainHdr} siteTopNavbar ${isMenuOpen ? "show-nav" : ""
          }`}
      >
        <div className="container">
          <div className={styles.hdrRow}>
            <div className={styles.hdrLogo}>
              <a href={"/"}>
                <img
                  src={"../../images/logo.png"}
                  alt="Your Company Logo"
                  title="Comapny Logo"
                />
              </a>
            </div>
            <div className={styles.hdrMenuPrt}>              
              <button
                className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
                type="button"
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className={styles.headerMenu}>
                <nav className="navbar navbar-light navbar-expand-lg">
                  <div
                    className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""
                      }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li>
                        <Link
                          to="/#about-sec"
                          onClick={() => handleMenuClick("about-sec")}
                        >
                          {" "}
                          {/* Replaced href with to */}
                          About Credit Secret
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#personalCredit"
                          onClick={() => {
                            handleMenuClick("personalCredit");
                          }}
                        >
                          Personal Credit
                        </Link>
                      </li>
                      <li>
                        <a
                          href="/#creditGenius"
                          onClick={() => {
                            handleMenuClick("creditGenius");
                          }}
                        >
                          Credit Genius
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditGames"
                          onClick={() => {
                            handleMenuClick("creditGames");
                          }}
                        >
                          Credit Games
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditSimulator"
                          onClick={() => {
                            handleMenuClick("creditSimulator");
                          }}
                        >
                          Credit Simulator
                        </a>
                      </li>

                      <li>
                        <a
                          href="/#downloadApp"
                          onClick={() => {
                            handleMenuClick("downloadApp");
                          }}
                        >
                          Download App
                        </a>
                      </li>
                      <li
                        onClick={togglePressMenu}
                        className={`dropdown ${isPressMenuOpen ? "open" : ""
                          }`}
                      >
                        <Link
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Press
                        </Link>
                        <ul className="dropdown-menu" role="menu">
                          <li>
                            <Link
                              target="_blank"
                              to="https://v.lemon8-app.com/s/djykMSspR"
                            >
                              Lemon8
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://the-credit-genius.en.softonic.com/iphone"
                            >
                              Softonic
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://medium.com/@yourprshop/a-game-changer-in-credit-monitoring-the-credit-genius-app-a660658e7277"
                            >
                              Medium Magazine
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://ventsmagazine.com/2023/05/06/an-overview-of-the-great-american-credit-secret/"
                            >
                              Vents Magazine
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://medium.com/@abuhurairaramay/the-credit-genius-app-revolutionizing-credit-monitoring-with-engaging-features-d9623d20e822"
                            >
                              Medium Magazine
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://onewestmagazine.com/magazine/2024/05/10/the-credit-genius-app-a-new-era-in-credit-monitoring/"
                            >
                              One West Magazine
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://influencerage.com/credit-management-made-simple-the-credit-genius-apps-user-friendly-approach/"
                            >
                              Influencerage.com
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://techplanet.today/post/the-credit-genius-app-transforming-credit-monitoring-with-innovative-features"
                            >
                              Tech Planet
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li
                        onClick={toggleBuzzMenu}
                        className={`dropdown ${isBuzzMenuOpen ? "open" : ""}`}
                      >
                        <Link
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Buzz
                        </Link>
                        <ul className="dropdown-menu" role="menu">
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.allnewsbuzz.com/the-credit-genius-app-pioneering-credit-monitoring-with-a-launch-set-for-june-1/"
                            >
                              All News Buzz
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.technewsvision.com/the-credit-genius-app-setting-a-new-benchmark-in-credit-monitoring-launching-june-1/"
                            >
                              Tech News Vision
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.technewsvision.com/the-credit-genius-app-mastering-credit-with-games-and-ai/"
                            >
                              Tech News Vision
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://losangelers.com/article/level-up-your-credit-score-the-credit-genius-app-your-personalized-credit-coach/"
                            >
                              The Los Angelers
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://techbullion.com/gamifying-credit-fitness-the-credit-genius-app-makes-building-credit-fun/"
                            >
                              Tech Bullion
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.thesunbulletin.com/achieve-credit-mastery-with-the-credit-genius-app-your-personalized-financial-advisor/"
                            >
                              The Sun Bulletin
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.newedgetimes.com/unlock-financial-freedom-with-the-credit-genius-apps-ai-driven-solutions/"
                            >
                              New Edge Times
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.newedgetimes.com/unlock-financial-freedom-with-the-credit-genius-apps-ai-driven-solutions/"
                            >
                              IMDb
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.imdb.com/name/nm16134877/"
                            >
                              IMDb 2
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.crunchbase.com/organization/the-credit-genius-app"
                            >
                              Crunchbase
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://en.wikialpha.org/wiki/The_Credit_Genius_App"
                            >
                              WikiAlpha
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://about.me/credit-genius-app/"
                            >
                              About
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.quora.com/What-is-The-Credit-Genius-App?ch=10&oid=191791781&share=0ba5ba95&srid=3L2skp&target_type=question"
                            >
                              Quora
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.quora.com/Is-The-Credit-Genius-App-legit"
                            >
                              Quora
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.reddit.com/r/creditgenius/s/YGBdbhw53Q"
                            >
                              Reddit
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.reddit.com/r/creditgenius/s/04YMOjqJLH"
                            >
                              Reddit
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://about.me/credit-genius-app"
                            >
                              About Me
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.yext.com/partnerpages/aroundme/credit-genius-west-hollywood-california-us-0a38c2"
                            >
                              Around Me
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================ Banner Section Start ================ */}
      <section className={styles.faqBannerSec}>
        <div className="container">
          <div className={styles.bannerRow}>
            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> How can I
              request the deletion of my account and associated data?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                {" "}
                Users can request account deletion by contacting our support
                team via email at{" "}
                <a href="mailto:info.tgacs@gmail.com">info.tgacs@gmail.com</a>,
                providing their account details and any additional required
                verification.
              </p>
            </div>

            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> What
              information do I need to provide to request account deletion?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                {" "}
                Please provide your full name, email address associated with
                your account, and any identity verification document if
                requested.
              </p>
            </div>

            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> How long will
              it take to process my deletion request?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                {" "}
                Account deletion requests are processed within 30 days from the
                date of the request.
              </p>
            </div>

            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> What happens to
              my data after my account is deleted?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                {" "}
                Upon deletion, your data is removed from active databases
                immediately but will remain in backups for up to 60 days before
                complete deletion.Is the deletion of my account and data
                reversible?Deletion is final. Users have a 7-day grace period to
                cancel their deletion request.
              </p>
            </div>

            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> Will all my
              data be deleted, including backups and logs?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                {" "}
                All personal and financial data associated with your account,
                including backups and logs, will be deleted.
              </p>
            </div>

            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> Do I need to
              unsubscribe from newsletters or marketing emails separately after
              my account is deleted?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                No, account deletion includes automatic unsubscription from all
                communications.
              </p>
            </div>

            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> What should I
              do if I encounter issues during the account deletion process?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                Please contact our support team via{" "}
                <a href="mailto:info.tgacs@gmail.com">info.tgacs@gmail.com</a>{" "}
                for assistance.
              </p>
            </div>

            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> How will I know
              once my account and data have been successfully deleted?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                You will receive an email confirmation once your account and all
                associated data have been deleted.
              </p>
            </div>

            <h4>
              {" "}
              <img src={"../../images/q-icon.png"} alt="icon" /> Are there any
              conditions under which my deletion request could be denied?
            </h4>
            <div className={styles.dFlex}>
              <div className={styles.marGap}>
                <img src={"../../images/a-icon.png"} alt="icon" />
              </div>
              <p>
                Deletion requests may be denied if there are pending
                transactions, disputes, or legal holds on your account.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* ================ Banner Section End ================ */}

      <Footer />
    </>
  );
};

export default Faq;
