import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link component
import styles from "./header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/reducers/auth";
import { titleCase } from "../../../utils/common";
import { auth } from "../../../firebase";
import constants from "../../../utils/constants";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth?.data?.user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPressMenuOpen, setIsPressMenuOpen] = useState(false);
  const [isBuzzMenuOpen, setIsBuzzMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const header = document.querySelector(".siteTopNavbar");
    const scrollTop = window.scrollY;
    if (header) {
      const navPosition = header.offsetTop;
      const navTop = window.scrollY || document.documentElement.scrollTop;
      if (navPosition < navTop) {
        header?.classList?.add("stickyHeader");
      } else {
        header?.classList?.remove("stickyHeader");
      }
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };
  const togglePressMenu = () => {
    setIsBuzzMenuOpen(false);
    setIsPressMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };
  const toggleBuzzMenu = () => {
    setIsPressMenuOpen(false);
    setIsBuzzMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - (window.innerWidth < 992 ? 150 : 120),
        behavior: "smooth",
      });
    }
  };

  const handleMenuClick = (sectionId) => {
    toggleMenu();
    scrollToSection(sectionId);
  };

  const handleMenuLinkClick = (sectionId) => {
    setIsMenuOpen(false);
    scrollToSection(sectionId);
  };

  const handleLogout = async () => {
    try {
      await dispatch(
        logout({ isTestEnv: constants.IS_TEST_ARRAY_URL })
      ).unwrap();
      await auth.signOut();
      navigate("/login", { replace: true });
    } catch (error) {
      console.log("unable to logout", error);
    }
  };

  return (
    <div
      className={`${styles.topMainHdr} siteTopNavbar ${isMenuOpen ? "show-nav" : ""
        }`}
    >
      <div className="container">
        {
          <div className={styles.hdrRow}>
            <div className={styles.hdrLogo}>
              <Link to={"/"}>
                {/* Replaced <a> with <Link> */}
                <img
                  src={"../../images/logo.png"}
                  alt="Credit Genius"
                  title="Credit Genius"
                />
              </Link>
            </div>
            {!userData && (
              <div className={styles.hdrMenuPrt}>                
                <button
                  className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
                  type="button"
                  onClick={toggleMenu}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className={styles.headerMenu}>
                  <nav className="navbar navbar-light navbar-expand-lg">
                    <div
                      className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""
                        }`}
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav">
                        <li>
                          <Link
                            to="/#about-sec"
                            onClick={() => handleMenuClick("about-sec")}
                          >
                            {" "}
                            {/* Replaced href with to */}
                            About Credit Secret
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/#personalCredit"
                            onClick={() => handleMenuClick("personalCredit")}
                          >
                            {" "}
                            {/* Replaced href with to */}
                            Personal Credit
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/#creditGenius"
                            onClick={() => handleMenuClick("creditGenius")}
                          >
                            {" "}
                            {/* Replaced href with to */}
                            Credit Genius
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/#creditGames"
                            onClick={() => handleMenuClick("creditGames")}
                          >
                            {" "}
                            {/* Replaced href with to */}
                            Credit Games
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/#creditSimulator"
                            onClick={() => handleMenuClick("creditSimulator")}
                          >
                            {" "}
                            {/* Replaced href with to */}
                            Credit Simulator
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/#downloadApp"
                            onClick={() => handleMenuClick("downloadApp")}
                          >
                            {" "}
                            {/* Replaced href with to */}
                            Download App
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/#blog"
                            onClick={() => handleMenuClick("blog")}
                          >
                            Blog
                          </Link>
                        </li>
                        <li
                          onClick={togglePressMenu}
                          className={`dropdown ${isPressMenuOpen ? "open" : ""
                            }`}
                        >
                          <Link
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Press
                          </Link>
                          <ul className="dropdown-menu" role="menu">
                            <li>
                              <Link
                                target="_blank"
                                to="https://v.lemon8-app.com/s/djykMSspR"
                              >
                                Lemon8
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://the-credit-genius.en.softonic.com/iphone"
                              >
                                Softonic
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://medium.com/@yourprshop/a-game-changer-in-credit-monitoring-the-credit-genius-app-a660658e7277"
                              >
                                Medium Magazine
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://ventsmagazine.com/2023/05/06/an-overview-of-the-great-american-credit-secret/"
                              >
                                Vents Magazine
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://medium.com/@abuhurairaramay/the-credit-genius-app-revolutionizing-credit-monitoring-with-engaging-features-d9623d20e822"
                              >
                                Medium Magazine
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://onewestmagazine.com/magazine/2024/05/10/the-credit-genius-app-a-new-era-in-credit-monitoring/"
                              >
                                One West Magazine
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://influencerage.com/credit-management-made-simple-the-credit-genius-apps-user-friendly-approach/"
                              >
                                Influencerage.com
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://techplanet.today/post/the-credit-genius-app-transforming-credit-monitoring-with-innovative-features"
                              >
                                Tech Planet
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li
                          onClick={toggleBuzzMenu}
                          className={`dropdown ${isBuzzMenuOpen ? "open" : ""}`}
                        >
                          <Link
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Buzz
                          </Link>
                          <ul className="dropdown-menu" role="menu">
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.allnewsbuzz.com/the-credit-genius-app-pioneering-credit-monitoring-with-a-launch-set-for-june-1/"
                              >
                                All News Buzz
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.technewsvision.com/the-credit-genius-app-setting-a-new-benchmark-in-credit-monitoring-launching-june-1/"
                              >
                                Tech News Vision
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.technewsvision.com/the-credit-genius-app-mastering-credit-with-games-and-ai/"
                              >
                                Tech News Vision
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://losangelers.com/article/level-up-your-credit-score-the-credit-genius-app-your-personalized-credit-coach/"
                              >
                                The Los Angelers
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://techbullion.com/gamifying-credit-fitness-the-credit-genius-app-makes-building-credit-fun/"
                              >
                                Tech Bullion
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.thesunbulletin.com/achieve-credit-mastery-with-the-credit-genius-app-your-personalized-financial-advisor/"
                              >
                                The Sun Bulletin
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.newedgetimes.com/unlock-financial-freedom-with-the-credit-genius-apps-ai-driven-solutions/"
                              >
                                New Edge Times
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.newedgetimes.com/unlock-financial-freedom-with-the-credit-genius-apps-ai-driven-solutions/"
                              >
                                IMDb
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.imdb.com/name/nm16134877/"
                              >
                                IMDb 2
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.crunchbase.com/organization/the-credit-genius-app"
                              >
                                Crunchbase
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://en.wikialpha.org/wiki/The_Credit_Genius_App"
                              >
                                WikiAlpha
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://about.me/credit-genius-app/"
                              >
                                About
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.quora.com/What-is-The-Credit-Genius-App?ch=10&oid=191791781&share=0ba5ba95&srid=3L2skp&target_type=question"
                              >
                                Quora
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.quora.com/Is-The-Credit-Genius-App-legit"
                              >
                                Quora
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.reddit.com/r/creditgenius/s/YGBdbhw53Q"
                              >
                                Reddit
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.reddit.com/r/creditgenius/s/04YMOjqJLH"
                              >
                                Reddit
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://about.me/credit-genius-app"
                              >
                                About Me
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="https://www.yext.com/partnerpages/aroundme/credit-genius-west-hollywood-california-us-0a38c2"
                              >
                                Around Me
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            )}
            {userData && (
              <div className={styles.user_profile_right}>
                <div className={styles.user_box}>
                  <img src={"../../images/account_box.svg"} alt="account_box" />
                  {titleCase(userData?.firstName) || ""}
                  <div className={styles.userDownArrow}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#004f9c"
                    >
                      <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                    </svg>
                  </div>
                  <div className={styles.user_dropdown}>
                    <ul>
                      <li>
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#5f6368"
                          >
                            <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
                          </svg>
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link onClick={handleLogout}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#004f9c"
                          >
                            <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
                          </svg>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default Header;
