import React from "react";
import { ErrorMessage, Formik } from "formik";
import styles from "../profile/profile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { titleCase } from "../../../utils/common";
import { InputErrorMessage } from "../../components/inputErrorMessage";
import toastMessage from "../../../utils/toastMessage";
import { me, updateUser } from "../../../store/reducers/user";
import { format } from "date-fns";
import { getStates } from "../../../store/reducers/states";
import constants from "../../../utils/constants";
import { Navigate, useNavigate } from "react-router-dom";

const Profile = ({ showValidation, setShowValidation, setIsEmailVerified , setActiveTab }) => {
  console.log("showValidation", showValidation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.profile?.data?.user);
  const [states, setStates] = useState([]);

  useEffect(() => {
    dispatch(getStates({ isTestEnv: constants.IS_TEST_ARRAY_URL }))
      .then((response) => {
        setStates(response?.payload?.states);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const initialValues = {
    firstName: titleCase(user?.firstName || ""),
    lastName: titleCase(user?.lastName || ""),
    emailAddress: user?.emailAddress || "",
    phoneNumber: user?.phoneNumber || "",
    dob: user?.dob ? format(new Date(user.dob), "yyyy-MM-dd") : "", // Format DOB
    ssn: user?.ssn || "",
    state: user?.address?.[0]?.state || "",
    city: user?.address?.[0]?.city || "",
    zipCode: user?.address?.[0]?.zip || "",
    address: user?.address?.[0]?.street || "",
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    emailAddress: yup
      .string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    dob: yup
      .string()
      .required("Date of Birth is required")
      .matches(/^\d{4}-\d{2}-\d{2}$/, "DOB must be in YYYY-MM-DD format"),
    ssn: yup.string().required("SSN is required").min(9).max(9),
    state: yup.string().required("State is required").min(2).max(2),
    city: yup.string().required("City is required"),
    zipCode: yup
      .string()
      .required("Zip Code is required")
      .matches(/^[0-9]*$/, "Only numeric values are allowed")
      .length(5, "Zip Code must be exactly 5 digits"),
    address: yup.string().required("Address is required"),
  });

  const onSubmit = (values) => {
    const payload = {
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      emailAddress: values.emailAddress || "",
      phoneNumber: values.phoneNumber || "",
      dob: values.dob || "",
      street: values.address || "",
      city: values.city || "",
      zip: values.zipCode || "",
      state: values.state || "",
      ssn: values.ssn || "",
    };

    dispatch(updateUser({ payload, isTestEnv: constants.IS_TEST_ARRAY_URL })).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constants.THUNK_STATUS.FULFILLED:
          setShowValidation(false);
          dispatch(me({ isTestEnv: constants.IS_TEST_ARRAY_URL })).then((response) => {
            switch (response?.meta?.requestStatus) {
              case constants.THUNK_STATUS.FULFILLED:
                toastMessage("success", "Profile updated successfully");
                setIsEmailVerified(response?.payload?.user?.isEmailVerified);
                setActiveTab("Personal Credit");
                return;
              case constants.THUNK_STATUS.REJECTED:
                toastMessage("error", response?.payload?.message);
                return;
              default:
                return;
            }
          });
          return;
        case constants.THUNK_STATUS.REJECTED:
          toastMessage("error", response?.payload?.message);
          return;
        default:
          return;
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values); // Your custom function call
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <div className={styles.profile_wrapper}>
          <h3>
            Welcome! <b>{titleCase(user?.firstName)}</b>
          </h3>
          <form onSubmit={handleSubmit}>
            <div className={styles.right_col_box}>
              <div className={styles.informationHeader}>
                <h4>Personal Details</h4>
                {showValidation && (
                  <span className={styles.infoIcon}>
                    <img src={"./images/info_icon.svg"} alt="information" />
                    Please Fill all the required fields!
                  </span>
                )}
              </div>
              <div className={styles.user_details}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>
                        First Name
                      </label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/person.svg"} alt="person" />
                        <input
                          type="text"
                          name="firstName"
                          value={values?.firstName || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="firstName" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>
                        Last Name
                      </label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/person.svg"} alt="person" />
                        <input
                          type="text"
                          name="lastName"
                          value={values?.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="lastName" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>
                        Social Security Number
                      </label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/person.svg"} alt="person" />
                        <input
                          type="text"
                          name="ssn"
                          value={values?.ssn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="ssn" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>
                        Date Of Birth
                      </label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/calendar.svg"} alt="calendar" />
                        <input
                          type="date"
                          name="dob"
                          value={values?.dob}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="dob" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>Address</label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/person.svg"} alt="person" />
                        <input
                          type="text"
                          name="address"
                          value={values?.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="address" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>City</label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/location.svg"} alt="location" />
                        <input
                          type="text"
                          name="city"
                          value={values?.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="city" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>State</label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/location.svg"} alt="location" />
                        <select
                          type="text"
                          name="state"
                          value={values?.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select State</option>
                          {states?.map((state) => (
                            <option key={state.id} value={state?.value}>
                              {state?.text}
                            </option>
                          ))}
                        </select>
                        <InputErrorMessage name="state" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>Zip</label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/location.svg"} alt="location" />
                        <input
                          type="text"
                          name="zipCode"
                          value={values?.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="zipCode" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className={styles.user_label_field}>
                        Phone Number
                      </label>
                      <div className={styles.user_form_group}>
                        <img src={"./images/call.svg"} alt="call" />
                        <input
                          type="text"
                          name="phoneNumber" // corrected
                          value={values?.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputErrorMessage name="phoneNumber" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={user?.isEmailVerified}
                    >
                      Update Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default Profile;
