import React, { useState } from "react";
import styles from "./footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faThreads } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const [setIsMenuOpen] = useState(false);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - (window.innerWidth < 992 ? 150 : 120),
        behavior: "smooth",
      });
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };

  const handleMenuClick = (sectionId) => {
    toggleMenu();
    scrollToSection(sectionId);
  };

  return (
    <div className={styles.footer_section}>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className={styles.footer_logo}>
              <a href="/">
                <img
                  src={"./images/logo.png"}
                  alt="Credit Genius"
                  title="Credit Genius"
                />
              </a>
              <p>
                Credit Genius App offers credit score monitoring and improvement
                tools alongside educational resources and personalized coaching
                powered by AI.
              </p>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <h4>Community</h4>
            <ul>
              <li>
                <Link
                  to="/#about-sec"
                  onClick={() => handleMenuClick("about-sec")}
                >
                  About Credit Secret
                </Link>
              </li>
              <li>
                <Link
                  to="/#personalCredit"
                  onClick={() => handleMenuClick("personalCredit")}
                >
                  Personal Credit
                </Link>
              </li>
              <li>
                <Link
                  to="/#creditGenius"
                  onClick={() => handleMenuClick("creditGenius")}
                >
                  Credit Genius
                </Link>
              </li>
              <li>
                <Link
                  to="/#creditGames"
                  onClick={() => handleMenuClick("creditGames")}
                >
                  Credit Games
                </Link>
              </li>
              <li>
                <Link
                  to="/#creditSimulator"
                  onClick={() => handleMenuClick("creditSimulator")}
                >
                  Credit Simulator
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-6">
            <h4>About Us</h4>
            <ul>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms-conditions">Terms & Conditions</a>
              </li>
              <li>
                <a href="/faq">Faq</a>
              </li>
              <li>
                <a href="/payment-terms">Payment Terms</a>
              </li>
            </ul>
          </div>
          <div className={styles.footerContsec}>
            <h4>Contact Us</h4>
            <p>
              8605 SANTA MONICA BLVD <br />
              #733357 WEST HOLLYWOOD, <br />
              CA 90069
            </p>
            {/* <p>info@lorem.com</p> */}
          </div>
          <div className={styles.footerContsec}>
            <h4>Stay up to date</h4>
            <p>Subscribe to our newsletter to receive our weekly feed</p>
            <div className={styles.email_subscribe}>
              <form>
                <input type="text" name="" placeholder="Your email address" />
                <button type="submit" className="dfltBtn radius50">
                  <span>Submit</span>
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className={styles.ftrCopyrightRow}>
          <div className={styles.copyrightLeftText}>
            <p>
              Copyright &copy; 2023 Credit Genius All Rights Reserved. |{" "}
              <a href="/privacy-policy">Privacy Policy</a> |{" "}
              <a href="/terms-conditions">Terms & Conditions</a> |{" "}
              <a href="/payment-terms">Payment Terms</a> |{" "}
              <a href="/faq">Faq</a>
            </p>
          </div>
          <div className={styles.copyrightRightSocialIcon}>
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61558574526620"
                >
                  <i className="fa fa-facebook-square"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://twitter.com/CreditGeniusApp">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/the-credit-genius-app/"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/creditgeniusapp/"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a target="_blank" href="www.youtube.com/@TheCreditGeniusApp">
                  <i className="fa fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.pinterest.com/0hws8v7lvgcbj87ue2549j7ttzcs9g/"
                >
                  <i className="fa fa-pinterest"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.threads.net/@creditgeniusapp"
                >
                  <FontAwesomeIcon icon={faThreads} />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.tiktok.com/@creditgeniusapp"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
