import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Success from "../success";
import { addToken } from "../../../store/reducers/userToken";

const Authentications = () => {
  const { userId } = useParams();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((store) => store?.auth?.data);

  useEffect(() => {
    window.addEventListener("array-event", handleArrayEvent);

    return () => {
      window.removeEventListener("array-event", handleArrayEvent);
    };
  }, [userId]);

  const handleArrayEvent = (arrayEvent) => {
    const { tagName, event, metadata = {} } = arrayEvent.detail;
    console.log("arrayEvent.detail", arrayEvent.detail);

    if (event !== "success" && !window?.ArrayAuthentication) {
      setSuccess(false);
      window.postMessage(
        JSON.stringify({
          type: event,
          metadata: metadata?.message ?? "",
          sectionName: metadata?.["section-name"], // Fixed property access
        })
      );
      return;
    }

    window.ArrayAuthentication?.postMessage(
      JSON.stringify({
        type: event,
        metadata: metadata?.message ?? "",
        sectionName: metadata?.["section-name"], // Fixed property access
      })
    );

    if (event === "success" && Object.keys(metadata).length > 0) {
      setSuccess(true);
      dispatch(
        addToken(
          {
            userId,
            userToken: metadata?.["user-token"],
          },
          false
        )
      );
    }
  };
  return (
    <div>
      {!success && (
        <array-authentication-kba
          appKey={process.env.REACT_APP_ARRAY_APP_KEY}
          userId={userId}
          showResultPages="true"
        ></array-authentication-kba>
      )}
      {success && <Success />}
    </div>
  );
};
export default Authentications;
