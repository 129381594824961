import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "../privacy-policy/privacy.module.scss";
const PaymentTerms = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPressMenuOpen, setIsPressMenuOpen] = useState(false);
  const [isBuzzMenuOpen, setIsBuzzMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const header = document.querySelector(".siteTopNavbar");
    const scrollTop = window.scrollY;
    if (header) {
      const navPosition = header.offsetTop;
      const navTop = window.scrollY || document.documentElement.scrollTop;
      if (navPosition < navTop) {
        header?.classList?.add("stickyHeader");
      } else {
        header?.classList?.remove("stickyHeader");
      }
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - (window.innerWidth < 992 ? 150 : 120),
        behavior: "smooth",
      });
    }
  };

  const handleMenuClick = (sectionId) => {
    toggleMenu();
    scrollToSection(sectionId);
  };

  const togglePressMenu = () => {
    setIsBuzzMenuOpen(false);
    setIsPressMenuOpen((prevState) => !prevState);
  };
  const toggleBuzzMenu = () => {
    setIsPressMenuOpen(false);
    setIsBuzzMenuOpen((prevState) => !prevState);
  };
  return (
    <>
      <div
        className={`${styles.topMainHdr} siteTopNavbar ${
          isMenuOpen ? "show-nav" : ""
        }`}
      >
        <div className="container">
          <div className={styles.hdrRow}>
            <div className={styles.hdrLogo}>
              <a href={"/"}>
                <img
                  src={"../../images/logo.png"}
                  alt="Your Company Logo"
                  title="Comapny Logo"
                />
              </a>
            </div>
            <div className={styles.hdrMenuPrt}>
              <button
                className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
                type="button"
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className={styles.headerMenu}>
                <nav className="navbar navbar-light navbar-expand-lg">
                  <div
                    className={`collapse navbar-collapse ${
                      isMenuOpen ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li>
                        <Link
                          to="/#about-sec"
                          onClick={() => handleMenuClick("about-sec")}
                        >
                          {" "}
                          {/* Replaced href with to */}
                          About Credit Secret
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#personalCredit"
                          onClick={() => {
                            handleMenuClick("personalCredit");
                          }}
                        >
                          Personal Credit
                        </Link>
                      </li>
                      <li>
                        <a
                          href="/#creditGenius"
                          onClick={() => {
                            handleMenuClick("creditGenius");
                          }}
                        >
                          Credit Genius
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditGames"
                          onClick={() => {
                            handleMenuClick("creditGames");
                          }}
                        >
                          Credit Games
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditSimulator"
                          onClick={() => {
                            handleMenuClick("creditSimulator");
                          }}
                        >
                          Credit Simulator
                        </a>
                      </li>

                      <li>
                        <a
                          href="/#downloadApp"
                          onClick={() => {
                            handleMenuClick("downloadApp");
                          }}
                        >
                          Download App
                        </a>
                      </li>
                      <li
                        onClick={togglePressMenu}
                        className={`dropdown ${isPressMenuOpen ? "open" : ""}`}
                      >
                        <Link
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Press
                        </Link>
                        <ul className="dropdown-menu" role="menu">
                          <li>
                            <Link
                              target="_blank"
                              to="https://v.lemon8-app.com/s/djykMSspR"
                            >
                              Lemon8
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://the-credit-genius.en.softonic.com/iphone"
                            >
                              Softonic
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://medium.com/@yourprshop/a-game-changer-in-credit-monitoring-the-credit-genius-app-a660658e7277"
                            >
                              Medium Magazine
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://ventsmagazine.com/2023/05/06/an-overview-of-the-great-american-credit-secret/"
                            >
                              Vents Magazine
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://medium.com/@abuhurairaramay/the-credit-genius-app-revolutionizing-credit-monitoring-with-engaging-features-d9623d20e822"
                            >
                              Medium Magazine
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://onewestmagazine.com/magazine/2024/05/10/the-credit-genius-app-a-new-era-in-credit-monitoring/"
                            >
                              One West Magazine
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://influencerage.com/credit-management-made-simple-the-credit-genius-apps-user-friendly-approach/"
                            >
                              Influencerage.com
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://techplanet.today/post/the-credit-genius-app-transforming-credit-monitoring-with-innovative-features"
                            >
                              Tech Planet
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li
                        onClick={toggleBuzzMenu}
                        className={`dropdown ${isBuzzMenuOpen ? "open" : ""}`}
                      >
                        <Link
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Buzz
                        </Link>
                        <ul className="dropdown-menu" role="menu">
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.allnewsbuzz.com/the-credit-genius-app-pioneering-credit-monitoring-with-a-launch-set-for-june-1/"
                            >
                              All News Buzz
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.technewsvision.com/the-credit-genius-app-setting-a-new-benchmark-in-credit-monitoring-launching-june-1/"
                            >
                              Tech News Vision
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.technewsvision.com/the-credit-genius-app-mastering-credit-with-games-and-ai/"
                            >
                              Tech News Vision
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://losangelers.com/article/level-up-your-credit-score-the-credit-genius-app-your-personalized-credit-coach/"
                            >
                              The Los Angelers
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://techbullion.com/gamifying-credit-fitness-the-credit-genius-app-makes-building-credit-fun/"
                            >
                              Tech Bullion
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.thesunbulletin.com/achieve-credit-mastery-with-the-credit-genius-app-your-personalized-financial-advisor/"
                            >
                              The Sun Bulletin
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.newedgetimes.com/unlock-financial-freedom-with-the-credit-genius-apps-ai-driven-solutions/"
                            >
                              New Edge Times
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.newedgetimes.com/unlock-financial-freedom-with-the-credit-genius-apps-ai-driven-solutions/"
                            >
                              IMDb
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.imdb.com/name/nm16134877/"
                            >
                              IMDb 2
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.crunchbase.com/organization/the-credit-genius-app"
                            >
                              Crunchbase
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://en.wikialpha.org/wiki/The_Credit_Genius_App"
                            >
                              WikiAlpha
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://about.me/credit-genius-app/"
                            >
                              About
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.quora.com/What-is-The-Credit-Genius-App?ch=10&oid=191791781&share=0ba5ba95&srid=3L2skp&target_type=question"
                            >
                              Quora
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.quora.com/Is-The-Credit-Genius-App-legit"
                            >
                              Quora
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.reddit.com/r/creditgenius/s/YGBdbhw53Q"
                            >
                              Reddit
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.reddit.com/r/creditgenius/s/04YMOjqJLH"
                            >
                              Reddit
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://about.me/credit-genius-app"
                            >
                              About Me
                            </Link>
                          </li>
                          <li>
                            <Link
                              target="_blank"
                              to="https://www.yext.com/partnerpages/aroundme/credit-genius-west-hollywood-california-us-0a38c2"
                            >
                              Around Me
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="payment-container">
        <div className="container">
          <h3 className="mt-5">CREDIT GENIUS PAYMENT TERMS</h3>
          <p>
            <strong>Effective Date: March 19, 2025</strong>
          </p>
          <p>
            By subscribing to the Credit Genius app, you agree to the following
            payment terms. Please read them carefully.
          </p>
          <h4>1. Subscription Plans & Pricing</h4>
          <p>Credit Genius offers the following subscription tiers:</p>
          <ul>
            <li>
              <strong>Silver Plan</strong> – $9.99/month
            </li>
            <li>
              <strong>Gold Plan</strong> – $19.99/month
            </li>
            <li>
              <strong>Platinum Plan</strong> – $29.99/month
            </li>
          </ul>
          <p>
            Each plan provides access to Credit Genius features as outlined in
            the app.
          </p>
          <h4>2. Billing & Auto-Renewal</h4>
          <ul>
            <li>
              Your payment will be charged to your Apple or Google Play account
              at the time of purchase.
            </li>
            <li>
              Subscriptions automatically renew unless canceled at least{" "}
              <strong>
                24 hours before the end of the current billing period.
              </strong>
            </li>
            <li>
              Renewal charges will be processed{" "}
              <strong>within 24 hours before the next billing cycle,</strong>{" "}
              and your account will be charged accordingly.
            </li>
            <li>
              The cost of renewal will be the same as the original subscription
              price unless otherwise notified.
            </li>
          </ul>
          <h4>3. Managing Your Subscription</h4>
          <ul>
            <li>
              You can manage or cancel your subscription through your Apple or
              Google Play account settings.
            </li>
            <li>
              Refunds will not be provided for unused portions of the
              subscription period.
            </li>
            <li>
              If you cancel, you will still have access to the subscription
              benefits until the end of the current billing cycle.
            </li>
          </ul>
          <h4>4. No Refund Policy</h4>
          <ul>
            <li>
              All payments are <strong>non-refundable</strong>. Once charged,
              fees are final and cannot be refunded or prorated for unused
              services.
            </li>
            <li>
              If you experience any issues, you may contact{" "}
              <a href="mailto:creditgeniusapp@gmail.com">
                creditgeniusapp@gmail.com
              </a>{" "}
              for assistance.
            </li>
          </ul>
          <h4>5. Changes to Subscription Pricing</h4>
          <ul>
            <li>
              Credit Genius reserves the right to modify pricing or subscription
              terms at any time.
            </li>
            <li>
              Users will be notified in advance of any changes affecting their
              current subscription.
            </li>
          </ul>
          <h4>6. Free Trials & Promotional Offers</h4>
          <ul>
            <li>
              If a free trial is available, you will not be charged during the
              trial period.
            </li>

            <li>
              If you do not cancel before the trial ends, your account will
              automatically be charged for the selected subscription plan.
            </li>
          </ul>
          <h4>7. Account Responsibility</h4>
          <ul>
            <li>
              You are responsible for all charges incurred under your account.
            </li>
            <li>
              If your payment method fails, we may suspend your access until a
              valid payment is processed.
            </li>
          </ul>
          <h4>Termination of Subscription</h4>
          <ul>
            <li>
              Credit Genius reserves the right to suspend or terminate
              subscriptions for violations of our <strong>Terms of Use</strong>{" "}
              or any unauthorized use of the app.
            </li>
          </ul>
          <p>
            For further assistance, contact{" "}
            <a href="mailto:creditgeniusapp@gmail.com">
              creditgeniusapp@gmail.com.
            </a>
          </p>
          <p>
            By subscribing, you agree to these <strong>Payment Terms,</strong>{" "}
            as well as our <strong>Terms of Use</strong> and{" "}
            <strong>Privacy Policy</strong>.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PaymentTerms;
