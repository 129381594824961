export default {
  API_BASE_ENDPOINT: process.env.REACT_APP_API_BASE_ENDPOINT,
  API_TEST_BASE_ENDPOINT: process.env.REACT_APP_API_TEST_BASE_ENDPOINT,
  AI_TEST_ENDPONIT: process.env.REACT_APP_AI_TEST_CHAT_ENDPONT,
  AI_ENDPONIT: process.env.REACT_APP_AI_CHAT_ENDPONT,
  IS_TEST_ARRAY_URL: Number(process.env.REACT_APP_IS_TEST_ARRAY_URL),
  IS_TEST_AI_URL: Number(process.env.REACT_APP_IS_AI_TEST_URL),
  THUNK_STATUS: {
    FULFILLED: "fulfilled",
    REJECTED: "rejected",
  },
  TOAST_TYPE: {
    SUCCESS: "success",
    ERROR: "error",
  },
};
