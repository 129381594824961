import styles from "./coming-soon.module.scss";

const ComingSoonModal = ({
  showModal,
  setShowModal,
}) => {

  return (
    <div className={`modal ${showModal ? "show" : ""}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <button
            type="button"
            className="modal-close-btn"
            onClick={() => setShowModal(false)}
          >
            <img src={"./images/close.svg"} alt="close" />
          </button>
          <div className={styles.comingSoonText}>
            <img src={"./images/app-logo.png"} alt="logo" /> 
            <h1><span>App is</span> 
            Coming soon!
            </h1>
            <h3>Stay tuned & Follow for more!</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonModal;
