import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDeleteRequest,
  fetchGetRequest,
  fetchPostRequest,
} from "../../utils/network";
import endpoint from "../../utils/endpoint";
import constants from "../../utils/constants";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: {},
  errors: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ payload, isTestEnv = constants.IS_TEST_ARRAY_URL }, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoint.user.login,
        {
          providerName: payload?.providerName,
          providerId: payload?.providerId,
          fcmToken: payload?.fcmToken,
          emailAddress: payload?.emailAddress,
          firstName: payload?.firstName,
          lastName: payload?.lastName,
        },
        null,
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async ({ isTestEnv = constants.IS_TEST_ARRAY_URL }, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoint.user.logout,
        {},
        isTestEnv
      );
      thunkAPI.dispatch(resetLoginData());
      localStorage.clear();
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeAccessTokenFromRefreshToken: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data.userToken.accessToken = action.payload.accessToken;
      localStorage.setItem(
        "storeAccessTokenFromRefreshToken",
        action.payload.accessToken
      );
    },
    resetLoginData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.errors = null;
      state.data = action.payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.errors = action.payload;
    });
  },
});
export const { storeAccessTokenFromRefreshToken, resetLoginData } = actions;
export default reducer;
