import React from "react";
import { useNavigate } from "react-router-dom";

const GameModal = ({
  showModal,
  setShowModal,
  setActiveTab,
}) => {
  const navigate = useNavigate();
  const onConfirm = () => {
    setShowModal(false);
    setActiveTab("Credit Games");

  };
  return (
    <div className={`modal ${showModal ? "show" : ""}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="information-content">
            <button
              type="button"
              className="modal-close-btn"
              onClick={() => setShowModal(false)}
            >
              <img src={"./images/close_icon.svg"} alt="close" />
            </button>
            <div className="game-modal-img">
              <img src={"./images/logo_2.png"} alt="credit_genius" /> 
            </div>
            <h4>{"Welcome to the Credit Genius!"}</h4>
            <p>{"Test your credit score Knowledge with a free game"}</p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onConfirm}
            >
              Let's Go
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameModal;
