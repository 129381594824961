import React from "react";
import { useEffect, useState } from "react";
import styles from "../games.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTriviaGame,
  submitTriviaGame,
} from "../../../../store/reducers/triviaGame";
import constants from "../../../../utils/constants";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Confetti from "react-confetti"; // Import Confetti component
import { useWindowSize } from "react-use"; // To get the current window size

const Trivia = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth?.data?.user);
  const [getQuestions, setGetQuestions] = useState([]);
  const { width, height } = useWindowSize(); // Get current window dimensions for confetti

  // State to track the current question index, game finished status, and answer correctness
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [gameFinished, setGameFinished] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(null);

  const [correctAnswerId, setCorrectAnswerId] = useState(null);
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);

  const [correctCount, setCorrectCount] = useState(0);

  // State variables to hold current question data
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentText, setCurrentText] = useState(null);
  const [currentOptions, setCurrentOptions] = useState([]);

  useEffect(() => {
    // Fetch trivia questions when the component loads
    dispatch(getTriviaGame()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constants.THUNK_STATUS.FULFILLED:
          setGetQuestions(response?.payload?.questions);
          break;
        default:
          break;
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (getQuestions?.length > 0 && !gameFinished) {
      const timeout = setTimeout(() => {
        setCurrentQuestionIndex((prevIndex) => {
          if (prevIndex < getQuestions.length - 1) {
            setCorrectAnswerId(null);
            setIsCorrectAnswer(null);
            return prevIndex + 1;
          } else {
            setGameFinished(true);
            return prevIndex; // Keep the index the same when game is finished
          }
        });
      }, 10000); // Change question every 10 seconds

      return () => clearTimeout(timeout); // Cleanup timeout on unmount or re-run
    }
  }, [getQuestions, currentQuestionIndex, gameFinished]);

  // Update the current question, image, text, and options when currentQuestionIndex changes
  useEffect(() => {
    if (getQuestions && currentQuestionIndex >= 0) {
      const question = getQuestions[currentQuestionIndex];
      setCurrentQuestion(question);
      setCurrentImage(question?.question?.image);
      setCurrentText(question?.question?.text);
      setCurrentOptions(question?.options || []);
    }
  }, [currentQuestionIndex, getQuestions]);

  if (!getQuestions || getQuestions.length === 0) {
    return (
      <div className={styles.gamesWrapper}>
        <div>Loading questions...</div>
      </div>
    );
  }
  const resetGame = () => {
    setCurrentQuestionIndex(0);
    setGameFinished(false);
    setCorrectCount(0);
    setSelectedAnswerId(null);
    setCorrectAnswerId(null);
    setIsCorrectAnswer(null);
  };
  // Display "Game Finished" when all questions are shown
  if (gameFinished) {
    return (
      <div className={styles.gamesWrapper}>
        <div className={styles.gameFinished}>
          <img src="../../images/right_party.gif" alt="party" />
          {correctCount >= 5 && (
            <>
              {/* Render Confetti when the player scores 5 or more */}
              <Confetti width={width} height={height} />
              <h1>Congratulations!</h1>
            </>
          )}
          {correctCount < 5 && <h1>Nice Try!</h1>}

          <p>
            You scored <b>{correctCount}/10</b>!{" "}
            {correctCount < 5 && "Almost a perfect score!"}
            {correctCount >= 5 && <b>You Rock!</b>}
          </p>
          {userDetails?.isNewUser && (
            <p>You earned a free consultation from the Credit Genius</p>
          )}
          <p>
            Now try your hand at another game or get a credit consultation with
            the Credit Genius
          </p>
          <button type="button" className="dfltBtn" onClick={resetGame}>
            <span>PLAY AGAIN</span>
          </button>
        </div>
      </div>
    );
  }

  const handleSubmit = (answerId, questionId) => {
    if (isCorrectAnswer !== null) return; // Prevent re-submission

    // Submit the answer
    const payload = {
      optionId: answerId,
      questionId: questionId,
    };
    dispatch(submitTriviaGame({ payload, isTestEnv: constants.IS_TEST_ARRAY_URL })).then(
      (response) => {
        switch (response?.meta?.requestStatus) {
          case constants.THUNK_STATUS.FULFILLED:
            setSelectedAnswerId(answerId);
            if (response?.payload?.isCorrect) {
              setCorrectCount(correctCount + 1);
              setCorrectAnswerId(answerId); // Mark correct answer
            } else {
              setCorrectAnswerId(0); // Set correct answer
            }
            setIsCorrectAnswer(response?.payload?.isCorrect);
            // Automatically move to the next question after 2-3 seconds
            setTimeout(() => {
              setCorrectAnswerId(null);
              setIsCorrectAnswer(null);
              setCurrentQuestionIndex((prevIndex) => {
                if (prevIndex < getQuestions.length - 1) {
                  return prevIndex + 1;
                } else {
                  setGameFinished(true);
                  return prevIndex; // Stay on the last question when finished
                }
              });
            }, 1000); // Delay of 2 seconds before moving to the next question
            return;
          default:
            return;
        }
      }
    );
  };

  return (
    <div className={styles.gamesWrapper}>
      <div className={styles.gamesBoxHeader}>
        <h4>Trivia Games</h4>
        <div className={styles.gamesBoxHeaderRight}>
          Question:{" "}
          <b>
            <span>{currentQuestionIndex + 1} / 10</span>
          </b>
        </div>
      </div>
      <div className={styles.gameWrapperBox}>
        <div className={styles.gameWrapperImg}>
          {/* Render the current image */}
          <img src={currentImage} alt="game_img" />
        </div>
        <div className={styles.gamesContent}>
          {/* Display the current question */}
          <h4>{currentText}</h4>
          {/* Timer resets with currentQuestionIndex */}
          <div className={styles.timer}>
            <CountdownCircleTimer
              key={`timer-${currentQuestionIndex}`} // Ensure unique key for each question
              isPlaying={!gameFinished} // Ensure it stops when the game finishes
              duration={10}
              size={80}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[7, 5, 2, 0]}
              strokeWidth={5}
              onComplete={() => {
                if (!gameFinished) {
                  return { shouldRepeat: false }; // Prevent repeating indefinitely
                }
              }}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </div>
          {isCorrectAnswer === true && (
            <div className={styles.rightAnswerGif}>
              <img src="../../images/party.gif" alt="party" />
            </div>
          )}
          <ul className={styles.answerSelect}>
            {(currentOptions || []).map((answer, index) => {
              let itemClass = "";
              if (correctAnswerId === answer?.id) {
                itemClass = styles.active; // Green when correct
              } else if (
                selectedAnswerId === answer?.id &&
                correctAnswerId !== null
              ) {
                itemClass = styles.incorrect; // Red if selected but incorrect
              } else if (
                selectedAnswerId === answer?.id &&
                correctAnswerId === null
              ) {
                itemClass = styles.selected; // Blue when selected before API response
              }

              return (
                <li
                  key={answer?.id}
                  className={itemClass}
                  onClick={() => {
                    if (correctAnswerId !== null) return;
                    setSelectedAnswerId(answer?.id);
                    handleSubmit(answer?.id, currentQuestion?.question?.id);
                  }}
                  aria-pressed={selectedAnswerId === answer?.id}
                >
                  <div className={styles.answerPoint}>
                    {String.fromCharCode(65 + index)}
                  </div>
                  <span>{answer?.text}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Trivia;
