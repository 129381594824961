import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import constants from "../../../utils/constants";
import { addToken, getUserToken } from "../../../store/reducers/userToken";

const CreditSimulator = () => {
  const { userId } = useParams();
  const [success, setSuccess] = useState(false);
  const [userToken, setUserToken] = useState("");
  const dispatch = useDispatch();

  const user = useSelector((store) => store?.auth?.data);

  useEffect(() => {
    dispatch(getUserToken({ userId })).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constants.THUNK_STATUS.FULFILLED:
          setUserToken(response?.payload?.userToken);
          return;

        default:
          return;
      }
    });
    window.addEventListener("array-event", handleArrayEvent);

    return () => {
      window.removeEventListener("array-event", handleArrayEvent);
    };
  }, [userToken, userId]);

  const handleArrayEvent = (arrayEvent) => {
    const { tagName, event, metadata = {} } = arrayEvent.detail;
    if (event !== "success" && !window?.ArrayAuthentication) {
      setSuccess(false);
      window.postMessage(
        JSON.stringify({
          type: event,
          metadata: metadata?.message ?? "",
          sectionName: metadata?.["section-name"], // Fixed property access
        })
      );
      return;
    }

    window.ArrayAuthentication?.postMessage(
      JSON.stringify({
        type: event,
        metadata: metadata?.message ?? "",
        sectionName: metadata?.["section-name"], // Fixed property access
      })
    );
    if (event === "success" && Object.keys(metadata).length > 0) {
      setSuccess(true);
      setUserToken(metadata?.["user-token"]);
      dispatch(
        addToken({ userId: userId, userToken: metadata?.["user-token"] })
      );
    }
  };

  return (
    <div>
      {userToken && (
        <array-credit-score-simulator
          appKey={process.env.REACT_APP_ARRAY_APP_KEY}
          userToken={userToken}
        ></array-credit-score-simulator>
      )}

      {!userToken && (
        <array-authentication-kba
          appKey={process.env.REACT_APP_ARRAY_APP_KEY}
          userId={userId}
          showResultPages="true"
        ></array-authentication-kba>
      )}
    </div>
  );
};

export default CreditSimulator;
